<template>
  <div>
    <app-header @getHeight="getHeight"></app-header>
    <div style="background-color: #fafafa">
      <div class="max_width min_width">
        <div
          class="flex align_center padtb_20"
          :style="{ 'margin-top': offsetHeight + 'px' }"
        >
          <div class="f838 size_14">当前位置：</div>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <!-- <el-breadcrumb-item class="d8f">政策法规</el-breadcrumb-item> -->
            <el-breadcrumb-item class="" style="color: #ff6618"
              >服务产品</el-breadcrumb-item
            >
          </el-breadcrumb>
        </div>
      </div>
    </div>
    <div style="background-color: #fafafa">
      <div class="max_width min_width">
        <section class="bgfff">
          <div
            style="
              width: 1518px;
              height: 185px;
              padding: 30px 30px 50px;
              background-color: rgb(255, 221, 204);

              border-radius: 5px;
            "
          >
            <div>
              <div class="flex align_start" style="padding: 10px 0px">
                <div class="fnf size_15 weight marr_30 text_nowrap">
                  栏目类别：
                </div>
                <div class="text_line1" style="width: calc(100% - 75px)">
                  <span
                    class="fnf weight size_15 marr_30 laws_font"
                    :class="{ laws_tag: category == '' }"
                    @click="setTag('category')"
                    >全部</span
                  >
                  <span
                    class="fnf size_15 marr_30 laws_font"
                    v-for="(item, index) in options"
                    :key="index"
                    :class="{ laws_tag: category == item.id }"
                    @click="setTag('category', item.id)"
                    >{{ item.name }}</span
                  >
                </div>
              </div>
            </div>

            <div>
              <div class="flex align_start" style="padding: 10px 0px">
                <div class="fnf size_15 weight marr_30 text_nowrap">
                  服务模式：
                </div>
                <div class="text_line1" style="width: calc(100% - 75px)">
                  <span
                    class="fnf weight size_15 marr_30 laws_font"
                    :class="{ laws_tag: patterns == '' }"
                    @click="setTag('patterns')"
                    >全部</span
                  >
                  <span
                    class="fnf size_15 marr_30 laws_font"
                    v-for="(item, index) in pattern"
                    :key="index"
                    :class="{ laws_tag: patterns == item.id }"
                    @click="setTag('patterns', item.id)"
                    >{{ item.name }}</span
                  >
                </div>
              </div>
            </div>
            <!-- 搜索框 -->
            <div class="flex align_center mart_20">
              <div class="fnf size_15 weight text_nowrap">关键词搜索：</div>
              <div class="width_50 sear_search">
                <el-input placeholder="请输入关键词" v-model="keyword" @keyup.enter.native="search">
                  <i slot="suffix" class="el-icon-search" @click="search()"></i>
                </el-input>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div style="background-color: #fafafa; padding: 50px 0px">
      <div class="max_width min_width">
        <section class="bgfff">
          <div
            style="display: flex; flex-wrap: wrap; background-color: #fafafa"
          >
            <div
              class="productbox"
              style=""
              v-for="(item, index) in selectorder"
              :key="index"
              @click="searchData(item.id)"
            >
              <img
                :src="item.img"
                style="width: 270px; height: 171px; background: #ffddcc"
                alt=""
              />
              <div
                v-if="item.mode_price === ''"
                style="padding: 5px 15px; color: #ff6417"
                class="size_18 ttt text_line3"
              >
                免费
              </div>
              <div
                v-else
                style="padding: 5px 15px; color: #ff6417"
                class="size_18 ttt text_line3"
              >
                {{ item.mode_price }}元
              </div>
              <div style="padding: 5px 15px" class="size_16 ttt text_line3">
                {{ item.product_name }}
              </div>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  padding: 5px 15px;
                "
              >
                <div style="display: flex">
                  <img
                    src="../../assets/image/address.png"
                    style="width: 16px; height: 18px"
                    alt=""
                  />
                  <div
                    style="margin-left: 10px; color: #808080"
                    class="size_14 ttt text_line3"
                  >
                    {{ item.city_name }}
                  </div>
                </div>
                <div class="size_14 ttt text_line3" style="color: #808080">
                  {{ item.num }}次交易
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="paginations.page_index"
        :page-sizes="paginations.page_sizes"
        :page-size="paginations.page_size"
        :layout="paginations.layout"
        :total="paginations.total"
      >
      </el-pagination>

    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
import Header from "@/components/Header/header.vue";
import Footer from "@/components/Footer/footer.vue";
export default {
  data() {
    return {
      offsetHeight: 0,
      keyword: "",
      options: [],
      num: "",
      allselectorder: [],
      category: "", // 栏目类别
      selectorder: [],
      pattern: [
        { id: 1, name: "公益性服务" },
        { id: 2, name: "市场化服务" },
        { id: 3, name: "公益性市场化服务" },
      ],
      patterns: "", //服务模式
      paginations: {
        page_index: 1, // 当前位于哪页
        total: 10, // 总数
        page_size: 10, // 1页显示多少条
        page_sizes: [10, 20, 50, 90], //每页显示多少条
        layout: "total, sizes, prev, pager, next, jumper", // 翻页属性
      },
    };
  },

  components: {
    "app-header": Header,
    "app-footer": Footer,
  },
  created() {
    this.getcatelist();
    this.selectlist();
    this.keyword = this.$route.query.search;
    this.num = this.$route.query.index;
    console.log(this.num, "this.num");
    if (this.num) {
      this.setTag("category", parseInt(this.num));
    }
  },
  methods: {
    search() {
      console.log(789456);
      this.selectlist();
    },
    setTag(name, id = "") {
      console.log(name, "name");
      console.log(id, "id");
      this[name] = id;
      console.log(this[name],"this.name[name]")
      this.paginations.page_index = 1;
      console.log("789");
      this.policyList = [];
      this.selectlist();
    },
    //获取分类
    getcatelist() {
      let data = { token: localStorage.eleToken };
      this.$get("cate_list", data).then((res) => {
        this.options = res.result;
        console.log(this.options, "this.options");
      });
    },

    selectlist(item) {
      let data = {
        keyword: this.keyword,
        pro_cate_id: this.category,
        mode: this.patterns,
        page: item ? item : this.paginations.page_index,
        limit: this.paginations.page_size,
      };
      this.$post("service_product_index", data).then((res) => {
        console.log(res, "res");
        this.allselectorder = res.result;

       

        if (res.count === "") {
          this.paginations.total = 0;
        } else {
          this.paginations.total = res.count;
        }
       

        if (this.allselectorder.length != 0) {
          this.selectorder = this.allselectorder.filter((item, index) => {
            return index < this.paginations.page_size;
          });
        } else {
          this.selectorder = [];
        }
      });
    },

    getHeight(data) {
      console.log(data, "data");
      this.offsetHeight = data;
    },
    searchData(id) {
      console.log(id);
      this.$router.push({ path: "/serveDetails", query: { id: id } });
    },
    handleSizeChange(page_size) {
      console.log(page_size, "page_size");
      this.paginations.page_index = 1;
      this.paginations.page_size = page_size;
      this.selectlist();
      this.selectorder = this.allselectorder.filter((item, index) => {
        return index < page_size;
      });
    },

    // 分页

    handleCurrentChange(page) {
      console.log(page, "handleCurrentChangepage");
      //获取当前页
      let index = this.paginations.page_size * (page - 1);
      //获取数据总数
      let nums = this.paginations.page_size * page;
      this.selectlist(page);
      //容器
      let tables = [];
      for (let i = index; i < nums; i++) {
        if (this.allselectorder[i]) {
          tables.push(this.allselectorder[i]);
        }
        this.selectorder = tables;
      }
    },
  },
};
</script>
<style scoped>
.sear_search {
  border-radius: 6px;
  margin-left: 12px;
}

.el-input__inner {
  background-color: #f5f5f5 !important;
  border: none !important;
  border-radius: 25px !important;
}
.sear_search .el-icon-search {
  color: #ff7129 !important;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  top: 8px;
  position: relative;
}

.sear_search .el-input__clear {
  height: 100% !important;
}
.laws_tag {
  padding: 3px 12px;

  color: #ff7129;
  background: #ffffff;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
}
::v-deep.el-pagination {
  text-align: center;
  padding: 20px 0px;
  background-color: #fafafa;
}
.productbox {
  width: 272px;
  height: 294px;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  margin: 0px 40px 40px 0px;
}
.productbox:hover {
  box-shadow: 0px 2px 8px 0px rgba(0, 132, 255, 0.15);
}
</style>
